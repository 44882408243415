import React from 'react';
import {useModal} from "@Contexts/ModalContext";
import {ReactComponent as Close} from "@Icons/payments/Close.svg";
import {useTranslation} from "react-i18next";

const EntryBasedPass = () => {
    const {t} = useTranslation();
    const {content, closeModal} = useModal();
    const {period} = content;

    return (
        <div className="flex flex-col space-y-5">
            <div className="flex w-full justify-between">
                <p>{t('entry_based_pass')}</p>
                <button onClick={closeModal}>
                    <Close/>
                </button>
            </div>
            <p className="text-textMuted text-sm">
                {t('permit_is_valid_for')} {` ${period}  ${period > 0 ? t('days').toLowerCase() : t('day').toLowerCase()}. `}
                {t('entry_is_available')}
            </p>
        </div>
    );
};

export default EntryBasedPass;