import React from 'react';
import clsx from 'clsx';
import styles from './input.module.scss';

const Input = ({ placeholder, label, name, type, value, onChange, disabled, error, maxLength, className }) => {
    return (
        <div className={styles.inputContainer}>
            <div className={styles.inputWrapper}>
                <input
                    type={type}
                    placeholder={placeholder}
                    name={name}
                    className={clsx(styles.input, { [styles.hasValue]: value }, className)}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    maxLength={maxLength}
                />
                <label
                    htmlFor={name}
                    className={clsx(styles.label, { [styles.shrink]: value || document.activeElement === document.querySelector(`input[name="${name}"]`) })}
                >
                    {label}
                </label>
            </div>
            {error && <p className="text-red-500 text-sm">{error}</p>}
        </div>
    );
};

export default Input;
