import {
    abonementCreate,
    abonementTypes,
    getBillingInfo,
    getBillingToken,
    getParking,
    m2payPayment,
    porticoPayment,
} from "../endpoints";
import axios from "axios";
import {v4 as uuidv4} from "uuid";
import axiosInstance from "./axiosInstance";
import {toast} from 'react-toastify';
import i18next from 'i18next';

const apiBaseUrl = process.env.REACT_APP_API_PARQOUR;
let idGenerator = () => {
    return uuidv4();
};
export const axiosBillingTokenFunc = async () => {
    const data = new URLSearchParams();
    data.append("grant_type", "client_credentials");

    let config = {
        method: "post",
        url: apiBaseUrl + getBillingToken,
        headers: {
            Authorization: "Basic bW9iaWxlOjhtSlJdLW00VCd3OG4hWT0=",
            "Content-Type": "application/x-www-form-urlencoded",
            'Accept-Language': localStorage.getItem('locale')
        },
        data: data,
    };

    try {
        const response = await axios(config);
        localStorage.setItem("billingToken", response.data.access_token);
        return response.data.access_token;
    } catch (err) {
        console.log(err);
    }
};

export const billingInfoFunc = (token, plateNumber, parkingGuid, serviceId) => {
    console.log("Base url: ", apiBaseUrl);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
        "Cookie",
        "AWSELB=A3DB135702664E3D6DB1017C189B3EE2672977A8E5CCBE9C506AF5794295DD7C5F6931B099AA8E2C5B141F5FDD721B1B9100BF3E305A916145FA7BBE5E482E8F511C0003D2; AWSELBCORS=A3DB135702664E3D6DB1017C189B3EE2672977A8E5CCBE9C506AF5794295DD7C5F6931B099AA8E2C5B141F5FDD721B1B9100BF3E305A916145FA7BBE5E482E8F511C0003D2"
    );

    const raw = JSON.stringify({
        command: "check",
        account: plateNumber,
        txn_id: idGenerator(),
        parking_uid: parkingGuid,
        service_id: serviceId,
    });
    console.log("RAW: ", raw);

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
    };

    return fetch(apiBaseUrl + getBillingInfo, requestOptions)
        .then((response) => response.json())
        .then((result) => {
            if (result.error) {
                return {
                    result: result,
                    code: 401,
                };
            } else {
                return {
                    result: result,
                    code: 200,
                };
            }
        })
        .catch((error) => {
            console.log(error);
        });
};

export const stripePaymentCheck = async (plateNumber, parkingUid) => {
    let data = JSON.stringify({
        parkingUID: parkingUid,
        plateNumber,
    });

    let config = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Accept-Language": localStorage.getItem('locale')
        },
        url: apiBaseUrl + `/stripe/v1/check`,
        data: data,
    };
    try {
        const response = await axios(config);
        return response;
    } catch (err) {
        if (axios.isCancel(err) || err.response?.status === 503) {
            // handle timeout exception and server unavailable code
            throw {
                status: "unavailable",
                response: {
                    data: {
                        message: "Service is unavailable now, please try again later",
                    },
                },
            };
        } else if (
            axios.isCancel(err) ||
            (err.response?.status === 500 &&
                err.response?.error === "Internal Server Error")
        ) {
            throw {
                status: "unavailable",
                response: {
                    data: {
                        message: "Something goes wrong, please try again later",
                    },
                },
            };
        } else if (
            !window.navigator.onLine &&
            !err.response &&
            err.code === "ERR_NETWORK"
        ) {
            throw {
                status: "NO INTERNET",
                response: {
                    data: {
                        message: "No internet connection",
                    },
                },
            };
        } else {
            // handle other types of errors
            throw err;
        }
    }
};

export const sendBMS = async (plateNumber, parkingUid) => {
    let data = JSON.stringify({
        parkingUid: parkingUid,
        plateNumber,
        returnUrl: window.location.origin,
    });

    let config = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        url: apiBaseUrl + `/ru/bms/send`,
        data: data,
    };

    return await axios(config);
};

export const axiosPortico = async (
    plateNumber,
    sum,
    token,
    parkingUid,
    email
) => {
    let data = {
        account: plateNumber,
        amount: sum,
        paymentToken: token,
        parkingUid: parkingUid,
        email: email,
    };
    let config = {
        method: "post",
        url: apiBaseUrl + porticoPayment,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            'Accept-Language': localStorage.getItem('locale')
        },
        data: data,
    };
    try {
        const response = await axios(config);
        console.log("Response of portico: ", response);
        return response;
    } catch (err) {
        return err;
    }
};

export const axiosStripe = async (
    plateNumber,
    sum,
    currency,
    successUrl,
    cancelUrl,
    parkingUid,
    isStripeCouponActive = false
) => {
    let data = {
        plateNumber: plateNumber,
        parkingUID: parkingUid,
        email: "hello@parqour.com",
        amount: sum,
        currency,
        successURL: successUrl,
        errorURL: cancelUrl,
    };


    let config = {
        method: "POST",
        headers: "Content-Type: application/json",
        url: apiBaseUrl + `/stripe/v1/checkout`,
        data: data,
    };
    try {
        return await axios(config);
    } catch (err) {
        alert("Something went wrong: ", err);
        console.log(err);
    }
};

export const axiosM2pay = async (
    plateNumber,
    sum,
    successUrl,
    cancelUrl,
    parkingUid
) => {
    let data = {
        plateNumber,
        parkingUid: process.env.NODE_ENV !== "development" ? 204 : parkingUid,
        sum,
        terminalName: process.env.NODE_ENV === "development" ? "test" : "golubica",
        successUrl: successUrl,
        errorUrl: cancelUrl,
        txnId: "txn_123456789",
    };

    let config = {
        method: "post",
        url: apiBaseUrl + m2payPayment,
        data: data,
    };
    try {
        const response = await axios(config);
        console.log("Response of m2pay: ", response);
        return response;
    } catch (err) {
        alert("Something went wrong: ", err);
        console.log(err);
    }
};

export const axiosGetAbonementInfo = (parkingGuid, locale) => {
    const encoder = new TextEncoder();
    const strJoin = process.env.REACT_APP_USERNAME + ":" + process.env.REACT_APP_PASSWORD;
    const strJoinData = encoder.encode(strJoin);
    const base64EncodedStr = btoa(String.fromCharCode(...strJoinData));

    let config = {
        method: 'post',
        url: '/mobile' + abonementTypes + parkingGuid,
        headers: {
            Authorization: `Basic ${base64EncodedStr}`,
            'Accept-Language': localStorage.getItem('locale') || 'en'
        }
    }

    return axiosInstance(config).then((resp) => {
        return {code: resp.status, message: resp.data}
    }).catch((err) => {
        return {code: err.response.status, message: 'error'}
    })
}

export const axiosAbonementCreate = (
    platenumber,
    parkingId,
    innerParkingId,
    typeId,
    dateStart,
    userId,
    email
) => {
    const encoder = new TextEncoder();
    const strJoin = process.env.REACT_APP_USERNAME + ":" + process.env.REACT_APP_PASSWORD;
    const strJoinData = encoder.encode(strJoin);
    const base64EncodedStr = btoa(String.fromCharCode(...strJoinData));

    let data = {
        platenumber,
        parkingId,
        innerParkingId: innerParkingId, // 1 for NAU, with parkingUid 999 1 is for Bakorda
        typeId,
        dateStart,
        userId,
        email
    }

    let config = {
        method: 'post',
        url: '/mobile' + abonementCreate,
        data,
        headers: {
            Authorization: `Basic ${base64EncodedStr}`,
            'Accept-Language': localStorage.getItem('locale') || 'en'
        }
    }

    return axiosInstance(config).then((resp) => {
        return {code: resp.status, message: resp.data.result}
    }).catch((err) => {
        handleError(err);
        throw err;
    })
}

export const getParkingByUid = async (
    parkingUid
) => {
    const encoder = new TextEncoder();
    const strJoin = process.env.REACT_APP_USERNAME + ":" + process.env.REACT_APP_PASSWORD;
    const strJoinData = encoder.encode(strJoin);
    const base64EncodedStr = btoa(String.fromCharCode(...strJoinData));

    let config = {
        method: 'get',
        url: apiBaseUrl + '/mobile' + getParking + parkingUid,
        headers: {
            'Accept-Language': localStorage.getItem('locale') || 'en'
        }
    }

    try {
        const res = await axios(config);
        return {
            message: res.data
        }
    } catch (err) {
        return {
            message: err
        }
    }
}

export const createLandingClient = async (phoneNumber, email, plateNumber) => {
    const encoder = new TextEncoder();
    const strJoin = "admin" + ":" + "123456_Ab170321";
    const strJoinData = encoder.encode(strJoin);
    const base64EncodedStr = btoa(String.fromCharCode(...strJoinData));

    let data = {
        phoneNumber,
        email,
        plateNumber
    };

    let config = {
        method: 'POST',
        url: apiBaseUrl + '/mobile/landing/client/create',
        data: data,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Basic ${base64EncodedStr}`,
            'Accept-Language': localStorage.getItem('locale') || 'en'
        }
    };

    try {
        const response = await axios(config);
        return {
            code: response.status,
            userId: response.data.userId,
            message: response.data.message
        };
    } catch (err) {
        console.log(err);
        toast.error("Error creating client: " + err);
        return {
            code: err.response ? err.response.status : 500,
            message: err.response ? err.response.data : 'Unknown error'
        };
    }
};

export const createAbonement = (
    platenumber,
    parkingId,
    innerParkingId,
    typeId,
    dateStart,
    userId,
    email
) => {
    const encoder = new TextEncoder();
    const strJoin = process.env.REACT_APP_USERNAME + ":" + process.env.REACT_APP_PASSWORD;
    const strJoinData = encoder.encode(strJoin);
    const base64EncodedStr = btoa(String.fromCharCode(...strJoinData));

    let data = {
        platenumber,
        parkingId,
        innerParkingId: innerParkingId,
        typeId,
        dateStart,
        userId,
        email
    }

    let config = {
        method: 'post',
        url: '/mobile' + abonementCreate,
        data,
        headers: {
            Authorization: `Basic ${base64EncodedStr}`,
            'Accept-Language': localStorage.getItem('locale') || 'en'
        }
    }

    return axiosInstance(config)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            if (err.response && err.response.data && err.response.data.message) {
                toast.error("Error: " + err.response.data.message);
            } else {
                toast.error("Error: " + err.message);
            }
            throw err;
        });
}

export const getLandingReceipt = async (receiptId) => {
    const encoder = new TextEncoder();
    const strJoin = "admin" + ":" + "123456_Ab170321";
    const strJoinData = encoder.encode(strJoin);
    const base64EncodedStr = btoa(String.fromCharCode(...strJoinData));

    let config = {
        method: 'GET',
        url: apiBaseUrl + `/mobile/landing/receipt/${receiptId}`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Basic ${base64EncodedStr}`,
            'Accept-Language': localStorage.getItem('locale') || 'en',
        }
    };

    return axios(config);
};

export const axiosPay = async (paymentMethod, plateNumber, parkingUid, successUrl, errorUrl, userId) => {
    const encoder = new TextEncoder();
    const strJoin = "admin" + ":" + "123456_Ab170321";
    const strJoinData = encoder.encode(strJoin);
    const base64EncodedStr = btoa(String.fromCharCode(...strJoinData));

    let data = {
        plateNumber,
        parkingUid,
        successUrl,
        errorUrl
    }

    let route = `/mobile/payment/v1/pay/no-auth/${paymentMethod}`;

    if (userId) {
        route = `/mobile/payment/v1/pay/no-auth/${paymentMethod}?userId=${userId}`
    }

    let config = {
        method: 'POST',
        url: apiBaseUrl + route,
        data,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Basic ${base64EncodedStr}`,
            'Accept-Language': localStorage.getItem('locale') || 'en'
        }
    };

    return axios(config).catch((err) => {
        toast.error(err);
    });
}

export const getPaymentProvidersByParkingId = async (parkingUid) => {
    const encoder = new TextEncoder();
    const strJoin = "admin" + ":" + "123456_Ab170321";
    const strJoinData = encoder.encode(strJoin);
    const base64EncodedStr = btoa(String.fromCharCode(...strJoinData));

    let config = {
        method: 'POST',
        url: apiBaseUrl + `/mobile/payment-links/list/${parkingUid}`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Basic ${base64EncodedStr}`,
            'Accept-Language': localStorage.getItem('locale') || 'en'
        }
    };

    return axios(config).catch((err) => {
        toast.error(err);
    });
}

export const getPaymentProviders = async () => {
    const encoder = new TextEncoder();
    const strJoin = "admin" + ":" + "123456_Ab170321";
    const strJoinData = encoder.encode(strJoin);
    const base64EncodedStr = btoa(String.fromCharCode(...strJoinData));

    let config = {
        method: 'GET',
        url: apiBaseUrl + '/mobile/public/payment-providers',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Basic ${base64EncodedStr}`,
            'Accept-Language': localStorage.getItem('locale') || 'en'
        }
    };

    return axios(config).catch((err) => {
        toast.error(err);
    });
};

export const sendLandingLetter = (userEmail, parkingName, parkingAddress, plateNumber, dateStart, dateEnd, sum, currency) => {

    let data = {
        userEmail,
        parkingName,
        parkingAddress,
        plateNumber,
        dateStart,
        dateEnd,
        sum,
        currency
    }

    let config = {
        method: 'POST',
        url: apiBaseUrl + '/data-delivery/data/send/letter',
        data,
        headers: {
            'Content-Type': 'application/json',
            'Accept-Language': localStorage.getItem('locale') || 'en'
        }
    };

    return axios(config).catch((err) => {
        toast.error(err);
    });
}

export const paymentCheck = async (plateNumber, parkingUid) => {
    let config = {
        method: 'POST',
        url: apiBaseUrl + '/mobile/public/payment-gateway/check',
        data: {plateNumber, parkingUid},
        headers: {
            'Content-Type': 'application/json',
            'Accept-Language': localStorage.getItem('locale') || 'en'
        }
    };

    return axios(config).catch((err) => {
        toast.error(err);
    });
}

export const wafaRequest = async (plateNumber, parkingUid, phone) => {
    let config = {
        method: 'POST',
        url: apiBaseUrl + '/wafa/payment/request',
        data: { plateNumber, parkingUid, phone },
        headers: {
            'Content-Type': 'application/json',
            'Accept-Language': localStorage.getItem('locale') || 'en'
        }
    };

    try {
        const res = await axios(config);
        return res.data;
    } catch (err) {
        handleError(err);
        throw err; // Повторно выбрасываем ошибку, чтобы вы могли обрабатывать ее дальше
    }
};

export const wafaConfirm = async (referenceId, otp) => {
    let config = {
        method: 'POST',
        url: apiBaseUrl + '/wafa/payment/confirm',
        data: { referenceId, otp },
        headers: {
            'Content-Type': 'application/json',
            'Accept-Language': localStorage.getItem('locale') || 'en'
        }
    };

    try {
        const res = await axios(config);
        return res.data;
    } catch (err) {
        handleError(err);
        throw err;
    }
};

export const wafaResend = async (phone) => {
    let config = {
        method: 'POST',
        url: apiBaseUrl + `/wafa/payment/resendOTP?phone=${phone}`,
        headers: {
            'Content-Type': 'application/json',
            'Accept-Language': localStorage.getItem('locale') || 'en'
        }
    };

    try {
        const res = await axios(config);
        return res.data;
    } catch (err) {
        handleError(err);
        throw err;
    }
};

const handleError = (err) => {
    if (err.response) {
        const { status, data } = err.response;
        const message = data?.message || i18next.t('an_unexpected_error_ocurred');

        if (status >= 400 && status < 500) {
            toast.error(`${i18next.t('error')}: ${message}`);
        } else if (status >= 500) {
            toast.error(`${i18next.t('error')}: ${message}`);
        }
    } else if (err.request) {
        toast.error(i18next.t('no_response_from_server'));
    } else {
        toast.error(`${i18next.t('error')}: ${err.message}`);
    }
};

export const getEmirateModels = async () => {
    let config = {
        method: 'GET',
        url: apiBaseUrl + '/mobile/public/emirates-models',
        headers: {
            'Content-Type': 'application/json',
            'Accept-Language': localStorage.getItem('locale') || 'en'
        }
    };

    return axios(config).catch((err) => {
        toast.error(err);
    });
}

export const getEmirateAbonementsRestrictions = async () => {
    let config = {
        method: 'GET',
        url: apiBaseUrl + '/mobile/public/abonement/restricted/regions',
        headers: {
            'Content-Type': 'application/json',
            'Accept-Language': localStorage.getItem('locale') || 'en'
        }
    };

    return axios(config).catch((err) => {
        toast.error(err);
    });
}