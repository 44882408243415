import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {ReactComponent as ArrowLeft} from "@Images/payments/ArrowLeft.svg";
import {stripePaymentCheck} from "@API/axiosRequest";
import {useLocation, useNavigate} from "react-router-dom";
import FormContainer from "@UI/kit/FormContainer/FormContainer";
import {ReactComponent as Car} from "@Icons/payments/Car.svg";
import {ReactComponent as QuestionOutlined} from "@Icons/payments/QuestionOutlined.svg";
import {ReactComponent as CircleCheckFilled} from "@Icons/payments/CircleCheckFilled.svg";
import {useModal} from "@Contexts/ModalContext";
import {currencySymbols} from "@Static/static";
import {ReactComponent as ArrowRight} from "@Icons/payments/ArrowRight.svg";
import {ReactComponent as Card} from "@Icons/payments/Card.svg";
import {getPaymentProviders, getPaymentProvidersByParkingId} from "@API/axiosRequest";
import Button from "@UI/kit/Button/Button";
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import Loader from "../../../../UI/kit/Loader/Loader";
import {useTranslation} from "react-i18next";
import {axiosPay, paymentCheck} from "../../../../api/axiosRequest";
import i18n from "i18next";
import {NetworkContext} from "../../../../contexts/NetworkContext";

const formatDateTime = (dateString) => {
    dayjs.locale(i18n.language);
    return dayjs(dateString).format('D MMMM YYYY, HH:mm');
}

const formatEndDateTime = (dateString, days) => {
    dayjs.locale(i18n.language);
    return dayjs(dateString)
        .add(days, 'day')
        .format('D MMMM YYYY, HH:mm');
}

const PaymentSubscription = () => {
    const {isOnline} = useContext(NetworkContext);
    const {t} = useTranslation();
    const location = useLocation();
    const {info, plateNumber, parkingUid, startDate, selectedProvider} = location.state;
    const navigate = useNavigate();
    const initialLoad = useRef(true);
    const [checkResponse, setCheckResponse] = useState();
    const [parking, setParking] = useState();
    const [permitType, setPermitType] = useState('');
    const [isLoading, setIsLoading] = useState();
    const [subscription, setSubscription] = useState();

    const check = useCallback(async () => {
        await paymentCheck(plateNumber, parkingUid).then((res) => {
            setCheckResponse(res.data);
        });
    }, []);

    useEffect(() => {
        if (initialLoad.current) {
            setIsLoading(true);
            check().finally(() => setIsLoading(false));

            initialLoad.current = false;
        }
    }, []);

    useEffect(() => {
        const parking = JSON.parse(localStorage.getItem('parking'));
        const permitType = localStorage.getItem('permitType');
        const subscription = JSON.parse(localStorage.getItem('selectedSubscription'));

        if (parking) {
            setParking(parking);
        }

        if (permitType) {
            setPermitType(permitType);
        }

        if (subscription) {
            setSubscription(subscription);
        }
    }, []);

    if (isLoading) {
        return (
            <FormContainer>
                <div className="w-full flex justify-center items-center">
                    <Loader/>
                </div>
            </FormContainer>
        )
    }

    if (!isOnline) {
        return (
            <FormContainer>
                <div className="flex w-full flex-col space-y-3 items-center">
                    <p className="text-alertRed text-center">{t("no_internet_connection")}</p>
                </div>
            </FormContainer>
        )
    }

    return (
        <FormContainer>
            <div className="w-full flex justify-center relative">
                <button onClick={() => navigate(-1)} className="absolute left-0 top-1">
                    <ArrowLeft/>
                </button>
                <p>{t('payment')}</p>
            </div>

            <div className="flex flex-col space-y-2">
                <p className="text-textLoud text-lg">{parking?.name}</p>
                <div className="flex space-x-1">
                    <Car/>
                    <p className="text-textMuted text-base">{plateNumber}</p>
                </div>
            </div>

            <div className="w-full">
                <p className="text-textLoud text-lg">{parking?.name}</p>
                <p className="text-textNormal text-sm">{parking?.address}</p>
            </div>

            {
                permitType === 'Entry-Based pass' ? <EntryBasedInfo/> :
                    <SubscriptionInfo permitType={permitType} startDate={formatDateTime(startDate)}
                                      endDate={formatEndDateTime(startDate, subscription?.period)}/>
            }

            <div className="w-full flex justify-between">
                <p className="text-textLoud text-xl">{t('total')}</p>
                <p className="text-textLoud text-xl">{checkResponse?.sum} {currencySymbols[checkResponse?.currency]}</p>
            </div>

            {permitType === 'Entry-Based pass' &&  <EntryBasedDescription/>}

            <ChooseMethodAndPay parking={parking} plateNumber={plateNumber} sum={checkResponse?.sum} selectedProvider={selectedProvider}/>
        </FormContainer>
    );
};

export default PaymentSubscription;

const EntryBasedInfo = () => {
    const {openModal} = useModal();
    const [subscription, setSubscription] = useState();
    const {t} = useTranslation();

    useEffect(() => {
        const subscription = JSON.parse(localStorage.getItem('selectedSubscription'));

        if (subscription) {
            setSubscription(subscription);
        }
    }, [])

    return (
        <div className="w-full flex flex-col space-y-4">
            <div className="flex flex-col space-y-1">
                <p>{t('entry_based_pass')}</p>
                <button className="flex space-x-1" onClick={() => openModal('entryBasedPass', {
                    period: subscription?.period
                })}>
                    <p className="text-link">{t('tariff_details')}</p>
                    <QuestionOutlined/>
                </button>
            </div>

            <div>
                <div className="flex justify-between">
                    <p className="text-textNormal text-base font-normal">{t('number_of_entries')}</p>
                    <p className="text-textNormal text-base font-medium">{subscription?.numberOfAllowedEntries} {t('pcs')}</p>
                </div>
                <div className="flex justify-between">
                    <p className="text-textNormal text-base font-normal">{t('duration')}</p>
                    <p className="text-textNormal text-base font-medium">{subscription?.period} {t('days')}</p>
                </div>
                <div className="flex justify-between">
                    <p className="text-textNormal text-base font-normal">{t('start_date')}</p>
                    <p className="text-textNormal text-base font-medium">{t('from_next_entry')}</p>
                </div>
            </div>
        </div>
    )
}

const SubscriptionInfo = ({permitType, startDate, endDate}) => {
    const [subscription, setSubscription] = useState();
    const {t} = useTranslation();

    useEffect(() => {
        const subscription = JSON.parse(localStorage.getItem('selectedSubscription'));

        if (subscription) {
            setSubscription(subscription);
        }
    }, [])

    return (
        <div className="w-full flex flex-col space-y-4">
            <div className="flex flex-col space-y-1">
                <p>{permitType}</p>
            </div>

            <div>
                <div className="flex justify-between">
                    <p className="text-textNormal text-base font-normal">{t('duration')}</p>
                    <p className="text-textNormal text-base font-medium">{subscription?.period} {t('days')}</p>
                </div>
                <div className="flex justify-between">
                    <p className="text-textNormal text-base font-normal">{t('start_date')}</p>
                    <p className="text-textNormal text-base font-medium">{startDate}</p>
                </div>
                <div className="flex justify-between">
                    <p className="text-textNormal text-base font-normal">{t('end_date')}</p>
                    <p className="text-textNormal text-base font-medium">{endDate}</p>
                </div>
            </div>
        </div>
    )
}

const EntryBasedDescription = () => {
    const {t} = useTranslation();

    return (
        <div className="w-full py-2.5 px-4 flex justify-between space-x-4 bg-warning rounded-lg border border-warning">
            <p className="text-textMuted text-sm font-normal">{t('permit_will_activate')}</p>
            <CircleCheckFilled/>
        </div>
    )
}

const ChooseMethodAndPay = ({plateNumber, parking, sum, selectedProvider}) => {
    const {openModal} = useModal();
    const [availablePaymentProviders, setAvailablePaymentProviders] = useState([]);
    const [paymentProvider, setPaymentProvider] = useState(selectedProvider || null);
    const [paymentProviders, setPaymentProviders] = useState({});
    const [isLoading, setIsLoading] = useState();
    const {t} = useTranslation();
    const initialLoad = useRef(true);

    const onChangePayment = (method) => {
        setPaymentProvider(method);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (paymentProvider === 'KASPI') {
            window.location.href = availablePaymentProviders.find((method) => method.paymentType === 'KASPI')?.link;
        } else if (paymentProvider === 'WAFACASH') {
            openModal('phoneVerification', {
                plateNumber, parkingUid: parking?.parkingUid
            });
        }
        else {
            setIsLoading(true);
            await axiosPay(paymentProvider, plateNumber, parking?.parkingUid,
                `${window.location.origin}/payment-success/${parking?.parkingUid}/${plateNumber}/`,
                `${window.location.origin}/payment-error`,
            ).then((res) => {
                window.location.href = res.data?.url;
            }).finally(() => setIsLoading(false));
        }
    }

    useEffect(() => {
        if (initialLoad.current) {
            if (parking) {
                getPaymentProviders().then((providers) => {
                    const methods = providers.data.reduce((acc, provider) => {
                        acc[provider?.providerName] = {
                            img: provider?.iconUrl,
                            text: provider?.providerName
                        };
                        return acc;
                    }, {});

                    getPaymentProvidersByParkingId(parking?.parkingUid).then((res) => {
                        const availableMethods = res.data.map(method => ({
                            paymentType: method.paymentType,
                            link: method.link,
                            ...methods[method.paymentType]
                        }));

                        if (availableMethods.length === 1) {
                            setPaymentProvider(availableMethods[0].paymentType);
                        }
                        setAvailablePaymentProviders(availableMethods);
                        setPaymentProviders(methods);
                    })
                });
            }
            initialLoad.current = false;
        }
    }, [parking])

    return (
        <div className="w-full flex flex-col items-center space-y-4">
            <button className="h-14 w-full max-w-[300px] p-4 border flex items-center rounded-2xl"
                    type="button"
                    onClick={() => {
                        availablePaymentProviders && openModal('choosePayment', {
                            onChange: onChangePayment,
                            availablePaymentProviders
                        })
                    }}
                    disabled={availablePaymentProviders.length === 0}
            >
                {paymentProvider && (
                    <div className="flex space-x-3 items-center">
                        <img src={paymentProviders[paymentProvider]?.img} alt="Payment Method"
                             className="h-7"/>
                        <p>{paymentProviders[paymentProvider]?.text}</p>
                    </div>
                )}

                {!paymentProvider && (
                    <div className="flex space-x-3 items-center">
                        <Card/>
                        <p className="text-sm">{t('choose_payment_method')}</p>
                        <ArrowRight/>
                    </div>
                )}
            </button>
            <Button disabled={!paymentProvider} text={isLoading ? t("loading") : t("pay")} onClick={handleSubmit}/>
        </div>
    )
}