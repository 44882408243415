import React from "react";
import {useModal} from "@Contexts/ModalContext";
import ChoosePayment from "@Modals/payment/ChoosePayment";
import EntryBasedPass from "@Modals/payment/EntryBasedPass";
import PhoneVerification from "@Modals/payment/PhoneVerification";

const Modal = () => {
    const {isOpen, closeModal, type} = useModal();

    if (!isOpen) return null;

    return (
        <div
            className="fixed inset-0 bg-modal z-20 flex items-center justify-center"
            onClick={closeModal}
        >
            <div
                onClick={(e) => e.stopPropagation()}
                className="relative w-full h-full xs:h-auto xs:w-[85%] rounded-none max-w-[640px] bg-white xs:rounded-xl shadow-modal p-4 lg:p-8"
            >
                {type === "choosePayment" && <ChoosePayment/>}
                {type === "entryBasedPass" && <EntryBasedPass/>}
                {type === "phoneVerification" && <PhoneVerification/>}
            </div>
        </div>
    );
};

export default Modal;
