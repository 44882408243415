import React, {useState, useEffect, useRef} from 'react';
import { useModal } from "@Contexts/ModalContext";
import Button from '@UI/kit/Button/Button';
import Input from '@UI/kit/Input/Input';
import { ReactComponent as ArrowLeft } from '@Icons/payments/ArrowLeft.svg';
import { useTranslation } from "react-i18next";
import { wafaRequest, wafaConfirm, wafaResend } from "../../api/axiosRequest";
import Loader from "../../UI/kit/Loader/Loader";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

const PhoneVerification = () => {
    const navigate = useNavigate();
    const { content, closeModal } = useModal();
    const { t } = useTranslation();
    const { plateNumber, parkingUid, phone: initialPhone } = content;

    const [step, setStep] = useState('phoneVerification');
    const [phone, setPhone] = useState(initialPhone || '+212 ');
    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const [referenceId, setReferenceID] = useState('');
    const [isResending, setIsResending] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [resendTimer, setResendTimer] = useState(0);
    const firstInputRef = useRef(null);

    useEffect(() => {
        let interval;
        if (resendTimer > 0) {
            interval = setInterval(() => {
                setResendTimer((prev) => prev - 1);
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [resendTimer]);

    useEffect(() => {
        if (step === 'verificationCode' && firstInputRef.current) {
            firstInputRef.current.focus();
        }
    }, [step]);

    const handlePhoneChange = (event) => {
        const input = event.target.value;

        if (!input.startsWith('+212')) {
            setPhone('+212 ');
        } else {
            const filteredPhone = input.replace(/[^\d\s]/g, '');
            const numberPart = filteredPhone.slice(4).replace(/\s/g, '');
            if (numberPart.length <= 9) {
                setPhone(`+212 ${numberPart}`);
            }
        }
    };

    const handlePhoneSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        const formattedPhone = phone.replace(/\D/g, '').replace(/^212/, '');
        await wafaRequest(plateNumber, parkingUid, `212${formattedPhone}`).then((res) => {
            setReferenceID(res?.referenceId);
            setStep('verificationCode');
            setResendTimer(59);
        })
            .finally(() => setIsLoading(false));
    };

    const handleOtpChange = (value, index) => {
        const newOtp = [...otp];
        newOtp[index] = value.slice(0, 1);
        setOtp(newOtp);

        if (value && index < otp.length - 1) {
            const nextInput = document.getElementById(`otp-input-${index + 1}`);
            if (nextInput) nextInput.focus();
        }
    };

    const handleOtpKeyDown = (event, index) => {
        if (event.key === 'Backspace' && !otp[index] && index > 0) {
            const prevInput = document.getElementById(`otp-input-${index - 1}`);
            if (prevInput) prevInput.focus();
        }
    };

    const handleOtpSubmit = async (event) => {
        event.preventDefault();
        const otpCode = otp.join('');

        setIsLoading(true);
        await wafaConfirm(referenceId, otpCode).then((res) => {
            closeModal();
            // navigate(`/payment-success/${parkingUid}/${plateNumber}`);
            window.location.href = `${process.env.REACT_APP_BASE_URL}/payment-success/${parkingUid}/${plateNumber}`
        }).finally(() => setIsLoading(false));
    };

    const resendCode = async () => {
        setIsResending(true);
        setResendTimer(59);

        const formattedPhone = phone.replace(/\D/g, '').replace(/^212/, '');

        await wafaResend(`212${formattedPhone}`).then((res) => {
        }).finally(() => {
            setIsResending(false);
        });
    };

    return (
        <div className="flex flex-col space-y-8 h-[90vh] sm:h-full">
            <div className="flex justify-center relative py-4">
                <button
                    onClick={() => {
                        if (step === 'phoneVerification') {
                            closeModal();
                        } else {
                            setStep('phoneVerification');
                        }
                    }}
                    type="button"
                >
                    <ArrowLeft className="absolute top-4 left-2" />
                </button>
                <p>{t('phone_verification')}</p>
            </div>

            {step === 'phoneVerification' && (
                <form onSubmit={handlePhoneSubmit} className="w-full flex justify-center pb-8">
                    <div className="flex flex-col items-center space-y-[14px] w-full max-w-[390px]">
                        <Input
                            type="text"
                            label={t('your_phone')}
                            onChange={handlePhoneChange}
                            value={phone}
                            placeholder="+212..."
                        />
                        {
                            isLoading ? <Loader /> : (
                                <Button
                                    type="submit"
                                    disabled={phone.length < 14}
                                    text={t('send_me_verification_code')}
                                    styles="bg-btnBlue hover:bg-btnBlueHover max-w-[390px] flex items-center justify-center"
                                />
                            )
                        }
                    </div>
                </form>
            )}

            {step === 'verificationCode' && (
                <form onSubmit={handleOtpSubmit} className="flex flex-col items-center space-y-6 pb-8">
                    <p className="text-center text-sm max-w-[180px]">{t('enter_the_code_from_sms')}</p>
                    <div className="flex space-x-2">
                        {otp.map((value, index) => (
                            <input
                                key={index}
                                id={`otp-input-${index}`}
                                type="text"
                                value={value}
                                onChange={(e) => handleOtpChange(e.target.value, index)}
                                onKeyDown={(e) => handleOtpKeyDown(e, index)}
                                ref={index === 0 ? firstInputRef : null}
                                className="w-12 h-12 p-2 text-center text-lg border rounded-lg"
                                maxLength={1}
                            />
                        ))}
                    </div>
                    {
                        isLoading ? <Loader /> : (
                            <Button type="submit" text={t('check_and_pay')} disabled={otp.some((val) => !val)} />
                        )
                    }
                    <button
                        type="button"
                        onClick={resendCode}
                        className={clsx("text-link text-sm", (resendTimer > 0 || isResending) && 'opacity-40')}
                        disabled={resendTimer > 0 || isResending}
                    >
                        {resendTimer > 0 ? `${t('resend_code')} (${resendTimer})` : t('resend_code')}
                    </button>
                </form>
            )}
        </div>
    );
};

export default PhoneVerification;
