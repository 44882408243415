import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import styles from "./uaeCarForm.module.scss";

import {setCode, setRegion, setReverse} from "@Features/uaeCarFormSlice";
import {useIsFirstRender} from "@Hooks/useIsFirstRender";
import {ErrorType} from "@Enums/errorTypes";
import {getEmirateAbonementsRestrictions, getEmirateModels} from "../../../api/axiosRequest";

const UaeCarForm = ({plateNumber, error, handlePlateNumber}) => {
    const [codesMap, setCodesMap] = useState({});
    const [regions, setRegions] = useState([]);
    const [restrictions, setRestrictions] = useState([]);
    const initialLoad = useRef(true);
    const dispatch = useDispatch();
    const {region, code, reverse} = useSelector((state) => state.uaeCarForm);
    const [emirateModels, setEmirateModels] = useState([]);
    const [isPlateNumberFocused, setIsPlateNumberFocused] = useState(false);
    const isFirstRender = useIsFirstRender();
    const {t} = useTranslation();

    useEffect(() => {
        if (!isFirstRender) {
            localStorage.setItem("region", region);
            localStorage.setItem("code", code);
            localStorage.setItem("isReversed", reverse ? "true" : "false");
        }
    }, [region, code, reverse, isFirstRender]);

    useEffect(() => {
        const storedRegion = localStorage.getItem("region");
        const storedCode = localStorage.getItem("code");
        const isReversed = localStorage.getItem("isReversed") === "true";

        if (storedRegion) {
            dispatch(setRegion(storedRegion));
        }
        if (storedCode) {
            dispatch(setCode(storedCode));
        }
        dispatch(setReverse(isReversed));
    }, [dispatch]);

    useEffect(() => {
        if (initialLoad.current) {
            const restrictions = getEmirateAbonementsRestrictions();
            const emirateModels = getEmirateModels();

            Promise.all([restrictions, emirateModels]).then(([restrictions, emirateModels]) => {
                const codesMap = emirateModels.data.reduce((acc, item) => {
                    acc[item.region] = {
                        code: item.code,
                        divided: item.divided,
                        prefix: item.prefix,
                        restriction: restrictions.data.includes(item.code),
                    };
                    return acc;
                }, {});

                const regions = emirateModels.data.map((item) => item.region);

                if (codesMap[region].restriction) {
                    localStorage.setItem("abonementRestriction", 'true');
                }

                setCodesMap(codesMap);
                setRegions(regions);
                setEmirateModels(emirateModels.data);
                setRestrictions(restrictions.data);
            });

            initialLoad.current = false;
        }
    }, []);

    const handleRegionChange = (e) => {
        const newRegion = e.target.value;

        if (codesMap[newRegion].restriction) {
            localStorage.setItem("abonementRestriction", 'true');
        } else {
            localStorage.removeItem("abonementRestriction");
        }

        dispatch(setRegion(newRegion));
    };
    const handleCodeChange = (e) => {
        dispatch(setCode(e.target.value === "no code" ? "" : e.target.value));
    };

    const handlePlateNumberFocus = () => {
        setIsPlateNumberFocused(true);
    };

    const handlePlateNumberBlur = () => {
        setIsPlateNumberFocused(false);
    };

    return (
        <div className={clsx(styles["uae-enter-car-form"], 'pb-5')}>
            <div className={styles["select-region-container"]}>
                <span>{t("select_vehicle_region")}</span>
                <select
                    className={styles["select-region"]}
                    onChange={handleRegionChange}
                    value={region}
                >
                    {regions.map((reg) => (
                        <option value={reg} key={reg}>
                            {reg}
                        </option>
                    ))}
                </select>
            </div>
            {codesMap[region]?.divided !== null ? (
                <div
                    className={clsx(
                        styles['select-code-container'],
                        codesMap[region]?.divided === false &&
                        styles['reverse']
                    )}
                >
                    <div className={clsx(styles['input-wrapper'], styles['plate-code'])}>
                        {codesMap[region]?.prefix.length !== 0 ? (
                            <select
                                id="vehicle-number-select"
                                onChange={handleCodeChange}
                                value={code === "no code" ? "" : code}
                                style={{width: "100%", paddingBottom: "3px"}}
                            >
                                {codesMap[region]?.prefix?.map((code) => (
                                    <option value={code === "no code" ? "" : code} key={code === "no code" ? "" : code}>
                                        {code}
                                    </option>
                                ))}
                            </select>
                        ) : (
                            <input
                                style={{padding: "18px 16px"}}
                                type="text"
                                onChange={handleCodeChange}
                                value={code}
                            />
                        )}

                        <label htmlFor="input-field">
                            {t("plate_code")}
                        </label>

                    </div>
                    <div className={clsx(styles['input-wrapper'], styles['uae-plate-number'])}>
                        <input
                            type="text"
                            className={`${error.type === ErrorType.USER_BASED && "red"}`}
                            onBlur={handlePlateNumberBlur}
                            onFocus={handlePlateNumberFocus}
                            value={plateNumber}
                            onChange={handlePlateNumber}
                            style={{
                                border:
                                    error.type === ErrorType.USER_BASED ? "1px solid red" : "",
                            }}
                        />
                        <label htmlFor="input-field" style={{top: "13px"}}>
                            {t("plate_number")}
                        </label>
                    </div>
                   {error.type === ErrorType.USER_BASED && error.msg}
                </div>
            ) : (
                <div className={clsx(styles['input-wrapper'], styles['uae-plate-number'])}>
                    <input
                        type="text"
                        value={plateNumber}
                        onChange={handlePlateNumber}
                        style={{padding: "18px 16px"}}
                        className={`${error.type === ErrorType.USER_BASED && "red"}`}
                    />
                    {error.type === ErrorType.USER_BASED && error.msg}
                    <label htmlFor="input-field" style={{top: "13px"}}>
                        {t("plate_number")}
                    </label>
                </div>
            )}
        </div>
    );
};

export default UaeCarForm;
