import React from 'react';
import logo from "@Images/parqour-logo.svg";
import LangToggle from "@UI/kit/LangToggle/LangToggle";
import AppStore from '@Images/payments/AppStore.png';
import GooglePlay from '@Images/payments/GooglePlay.png';
import Rights from "@UI/kit/Rights/Rights";
import {useTranslation} from "react-i18next";

const FormContainer = ({children, title, withApps, withAppDescription}) => {
    const {t} = useTranslation();

    return (
        <div className="xs:mt-0 w-full mt-5 min-h-screen flex flex-col justify-between items-center p-4">
            <div className="flex flex-col justify-center items-center flex-grow">
                <img src={logo} alt={"logo"}
                     className="absolute top-3 left-3 w-[67px] h-[17px] xs:static xs:w-[134px] xs:h-[34px] mb-5"/>
                {title}
                <div className="absolute top-3 right-6">
                    <LangToggle/>
                </div>
                <div
                    className="flex flex-col items-center space-y-4 bg-white rounded-2xl border border-form w-full px-4 py-4 max-w-[532px] sm:w-[532px] xs:px-[66px] xs:py-[45px]">
                    {children}
                </div>
            </div>

            {
                withAppDescription && (
                    <div className="mt-5 w-[285px] bg-[#F6F8FA] px-4 py-2 mb-6 rounded-lg">
                        <p className="text-xs">{t('download_app_description')}</p>
                    </div>
                )
            }

            {
                withApps && (
                    <div className="mt-5 flex space-x-4 mb-5">
                        <a href="https://play.google.com/store/search?q=parqour&c=apps" target="_blank"><img
                            src={GooglePlay} alt="Google Play"/></a>
                        <a href="https://apps.apple.com/us/app/parqour/id1612020104"><img src={AppStore}
                                                                                          alt="App Store"/></a>
                    </div>
                )
            }
            <div className="mt-auto">
                <Rights/>
            </div>

        </div>
    );
};

export default FormContainer;
