import React, {useContext, useEffect, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {isIOS} from "react-device-detect";
import clsx from "clsx";
import styles from "./enterCarForm.module.scss";

import UaeCarForm from "@Components/payment/uaeCarForm/UaeCarForm";

import Loader from "@UI/kit/Loader/Loader";
import LoadingSpinner from "@UI/kit/Loader/Spinner";

import {
    axiosBillingTokenFunc,
} from "@API/axiosRequest";
import {axiosChangePromocodeStatus} from "@API/promocode";

import useParkingInfo from "@Hooks/useParkingInfo";
import usePromoCode from "@Hooks/usePromoCode";
import {ErrorType} from "@Enums/errorTypes";

import {ReactComponent as QuestionOutlinedIcon} from '@Icons/payments/bms/questionOutlined.svg';
import {ReactComponent as ArrowBottomIcon} from "@Icons/payments/bms/arrowBottom.svg";
import plateNumbers from '@Images/payments/bms/plateNumbers.png';
import FormContainer from "../../../UI/kit/FormContainer/FormContainer";
import Button from "@UI/kit/Button/Button";
import Input from "@UI/kit/Input/Input";
import {paymentCheck} from "../../../api/axiosRequest";
import {NetworkContext} from "../../../contexts/NetworkContext";

const uaeSelectedFields = {
    region: "Dubai",
    code: "",
    reverse: false,
};

function EnterCarForm() {
    const {isOnline} = useContext(NetworkContext);
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    let {parkingUid, paymentMethod, promo, id, ticket} = useParams();
    let [searchParams] = useSearchParams();
    const {code, reverse} = useSelector((state) => state.uaeCarForm);
    const {parkingInfo} = useParkingInfo(parkingUid);
    const [promocodeInputActive, setPromocodeInputActive] = useState(false);
    const promocode = promo || "";
    const [plateNumber, setPlateNumber] = useState(
        localStorage.getItem("plateNumber") || ""
    );

    const [error, setError] = useState({
        msg: "",
        type: "",
    });
    const isUae = window.location.pathname.split("/")[1] === "uae";
    const {checkPromoCode, handlePromoCodeChange, promoCode} = usePromoCode(
        promocode,
        parkingUid,
        plateNumber
    );

    const [alertMessage, setAlertMessage] = useState("");
    const [isContinueLoading, setIsContinueLoading] = useState(false);
    isUae
        ? localStorage.setItem("isUae", "true")
        : localStorage.getItem("isUae") && localStorage.removeItem("isUae");

    if (isIOS && promo && searchParams.get("appInstalled") !== "no") {
        const currentLocation = window.location;
        window.location = `parqourScheme://promoCode?parkingUid=${parkingUid}&promoId=${id}&promoCode=${promoCode}`;
        setTimeout(function () {
            window.location = currentLocation + "?appInstalled=no";
        }, 500);
    }

    useEffect(() => {
        if (ticket) {
            handleContinue();
        }
    }, [ticket]);

    useEffect(() => {
        if (promoCode.trim() && id) {
            axiosChangePromocodeStatus(id).then((res) => {
                console.log("Promo changed res: ", res);
            });
        }

        axiosBillingTokenFunc();
        return () => {
        };
    }, [id, promoCode]);

    useEffect(() => {
        if (paymentMethod === "bms") {
            i18n.changeLanguage("ru");
        }
    }, []);

    useEffect(() => {
        const referrer = document.referrer;

        if (referrer.includes('turaq')) {
            localStorage.setItem('referrer', 'turaq');
        }
    }, []);

    useEffect(() => {
        localStorage.removeItem("abonementRestriction");
    }, []);

    const handleActivatePromocodeInput = () => {
        setPromocodeInputActive(true);
    }

    const handlePlateNumberChange = (event) => {
        let input_number = event.target.value;

        const letters_numbers = /^[a-zA-Z0-9]+$/;
        if (letters_numbers.test(input_number)) {
            setError({
                msg: "",
                type: "",
            });
            setAlertMessage("");
        } else {
            setError({
                msg: (
                    <div
                        style={{
                            textAlign: "center",
                            width: "100%",
                            fontSize: isUae ? "10px" : "12px",
                        }}
                        className={clsx(
                            styles.error_msg,
                            isUae ? "absolute -bottom-11 left-0" : ""
                        )}
                    >
                        {t("vehicle_number_can_contain")}
                    </div>
                ),
                type: ErrorType.USER_BASED,
            });
        }

        setPlateNumber(event.target.value.toUpperCase());
        localStorage.setItem("plateNumber", event.target.value);
    };

    const handleContinue = (event) => {
        if (event) {
            event.preventDefault();
        }

        if (promoCode && plateNumber) {
            checkPromoCode(plateNumber);
        } else {
            handleSubmit(event);
        }
    };

    const handleSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }
        setIsContinueLoading(true);

        let finalPlateNumber;
        if (isUae) {
            finalPlateNumber = reverse ? plateNumber + code : code + plateNumber;
        } else {
            finalPlateNumber = ticket || plateNumber;
        }
        paymentCheck(finalPlateNumber, parkingUid).then((resp) => {
            if (resp?.status === 200 && resp?.data?.result !== 1) {
                navigate(`/parking/${parkingUid}/info`, {
                    state: {
                        info: resp?.data,
                        plateNumber: finalPlateNumber,
                        parkingUid: parkingUid,
                        paymentMethod: paymentMethod,
                    },
                });
            } else if (resp?.data?.result === 1) {
                if (parkingInfo.abonementsEnabled) {
                    navigate(`/parking/${parkingUid}/info`, {
                        state: {
                            info: {result: "error"},
                            plateNumber: finalPlateNumber,
                            parkingUid: parkingUid,
                            paymentMethod: paymentMethod,
                        },
                    });
                } else {
                    setError({
                        msg: (
                            <div className={clsx(styles.error_msg, isUae ? styles['err-position'] : "")}>
                                {resp?.data?.message}
                            </div>
                        ),
                        type: ErrorType.USER_BASED,
                    });
                }
            } else {
                setError({
                    msg: (
                        <div className={styles.error_msg} style={{textAlign: "center"}}>
                            {t("apologies_an_issue_occured")}
                            <br/>
                            {t("try_again_later")}
                        </div>
                    ),
                    type: ErrorType.SERVICE_BASED,
                });
            }
        })
            .catch((err) => {
                if (err?.response?.status === 500) {
                    setError({
                        msg: (
                            <div className={styles.error_msg} style={{textAlign: "center"}}>
                                {t("apologies_an_issue_occured")}
                                <br/>
                                {t("try_again_later")}
                            </div>
                        ),
                        type: ErrorType.SERVICE_BASED,
                    });
                } else if (
                    err?.status === 404 ||
                    410 ||
                    417 ||
                    "unavailable" ||
                    "NO INTERNET"
                ) {
                    setError({
                        msg: (
                            <div className={styles.error_msg} style={{textAlign: "center"}}>
                                {err?.response?.data?.message}
                            </div>
                        ),
                        type: ErrorType.SERVICE_BASED,
                    });
                } else {
                    setError({
                        msg: (
                            <div className={styles.error_msg} style={{textAlign: "center"}}>
                                {t("apologies_an_issue_occured")}
                                <br/>
                                {t("try_again_later")}
                            </div>
                        ),
                        type: ErrorType.SERVICE_BASED,
                    });
                }
            }).finally(() => setIsContinueLoading(false));
    };
    const handleBlur = () => {
        if (plateNumber.length <= 4) {
            setError({
                msg: (
                    <div className={clsx(styles.error_msg, isUae ? styles['err-position'] : "")}>
                        {t("vehicle_number_must_greater_than")} {isUae ? "0" : "4"}
                    </div>
                ),
                type: ErrorType.USER_BASED,
            });
        }
    };

    if (!isOnline) {
        return (
            <FormContainer>
                <div className="flex w-full flex-col space-y-3 items-center">
                    <p className="text-alertRed text-center">{t("no_internet_connection")}</p>
                </div>
            </FormContainer>
        )
    }

    if (!parkingInfo) {
        return (
            <div className={"middle"}>
                <Loader/>
            </div>
        );
    }

    return (
        <FormContainer title={<div className="text-center mb-5">
            <p className="text-textNormal text-sm md:text-lg max-w-[260px] md:max-w-[330px]">{t("welcome_to_payment_page")}{" "}{t("pay_parking_fees")}</p>
        </div>}>
            {alertMessage && (
                <p className={clsx(styles.message, styles.message_success)}>
                    {alertMessage}
                </p>
            )}
            <form onSubmit={handleContinue} className="flex flex-col space-y-5">
                <div className="flex flex-col items-center mb-4">
                                <span className="text-textLoud text-lg text-center mb-2">
                                  {parkingInfo.response ? "" : parkingInfo?.name}
                                </span>
                    <span className="text-neutralSolid text-xs tracking-[.72px]">
                                    {t("parking")}
                    </span>
                </div>

                {isUae ? (
                    <UaeCarForm
                        plateNumber={plateNumber}
                        error={error}
                        handlePlateNumber={handlePlateNumberChange}
                    />
                ) : (
                    <>
                        <label className="mb-4">
                            <div className={clsx(styles["input-wrapper"], 'flex justify-center')}>
                                <Input
                                    className={clsx(
                                        'w-full md:w-[400px] h-[58px]',
                                        error.type === ErrorType.USER_BASED && "error",
                                        plateNumber && '!pt-8',
                                    )}
                                    type="text"
                                    value={ticket || plateNumber}
                                    placeholder={t("enter_vehicle_number")}
                                    onChange={handlePlateNumberChange}
                                    maxLength={10}
                                    onBlur={handleBlur}
                                />
                                {plateNumber.length > 0 && (
                                    <label
                                        htmlFor="input-field"
                                        className={`${
                                            error.type === ErrorType.USER_BASED && "red"
                                        }`}
                                    >
                                        {t("enter_vehicle_number")}
                                    </label>
                                )}
                            </div>
                            {error.type === ErrorType.USER_BASED &&
                                plateNumber.length > 0 &&
                                error.msg}
                        </label>
                        {parkingInfo?.promoCodesEnabled && promocodeInputActive && (
                            <div
                                className={clsx(styles["promoCode-container"], "col")}
                                style={{marginBottom: "16px", alignItems: "center"}}
                            >
                                <div className={styles["promoCode-container"]}>
                                    <input
                                        type="text"
                                        placeholder={"XXX-ABCDE or ABCDE"}
                                        className={clsx(
                                            error.type === ErrorType.USER_BASED && "error",
                                            'w-full md:w-[400px]'
                                        )}
                                        name="promoCode"
                                        value={promoCode}
                                        onChange={handlePromoCodeChange}
                                    />
                                    {promocode?.length > 0 && (
                                        <label htmlFor="promocode">{t("promo_code")}</label>
                                    )}
                                </div>
                                {error.type === ErrorType.USER_BASED &&
                                    promoCode.length > 0 &&
                                    error.msg}
                            </div>
                        )}
                    </>
                )}
                <div className="w-full flex justify-center">
                    {
                        isContinueLoading ? <LoadingSpinner/> :
                            <Button
                                type="submit"
                                disabled={
                                    plateNumber.length < (isUae ? 0 : 4) ||
                                    plateNumber.length > 10 ||
                                    (error.msg && error.type !== ErrorType.SERVICE_BASED)
                                }
                                text={t("continue")}
                            />
                    }
                </div>

                {parkingInfo?.promoCodesEnabled && !promocodeInputActive &&
                    <button onClick={handleActivatePromocodeInput} type="button">
                        <p className="text-link font-normal text-xs text-center">{t('enter_the_promocode')}</p>
                    </button>
                }


                {error.type === ErrorType.SERVICE_BASED && error.msg}
                <div
                    className="text-center text-xs text-textGray"
                >
                    {t("read_and_agree")} {" "}
                    {t("to_the")}{" "}
                    <a
                        className="text-link font-normal decoration-0"
                        href={
                            "https://parqour.kz/wp-content/uploads/2023/10/terms-of-use-en.pdf"
                        }
                    >
                        {t("terms_of_use_site")}
                    </a>
                </div>
                {paymentMethod === "bms" && <BMSInstruction/>}
            </form>
        </FormContainer>
    );
}

export default EnterCarForm;

const BMSInstruction = () => {
    const [isOpen, setIsOpen] = useState();

    const toggleArrow = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div className="flex flex-col space-y-1">
            <div className="flex items-center space-x-1 mt-6">
                <QuestionOutlinedIcon/>
                <p className="text-textGray font-normal text-sm">Инструкция ввода гос.номеров</p>
                <ArrowBottomIcon
                    className={`cursor-pointer transform transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`}
                    onClick={toggleArrow}
                />
            </div>
            {isOpen && <img src={plateNumbers} alt="plateNumbers"/>}
        </div>
    )
}