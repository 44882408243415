import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import FormContainer from "@UI/kit/FormContainer/FormContainer";
import Button from "@UI/kit/Button/Button";
import Error from "@Images/payments/Error.png"
import React, {useRef, useState} from "react";
import {paymentCheck} from "../../../api/axiosRequest";
import Loader from "../../../UI/kit/Loader/Loader";

const PaymentErrorForm = () => {
    const {t} = useTranslation();
    let navigate = useNavigate();
    const isUae = localStorage.getItem('isUae') === 'true';
    const parkingUid = useRef(localStorage.getItem("parkingUid"));
    const plateNumber = useRef(localStorage.getItem("plateNumber"));
    const paymentMethod = useRef(localStorage.getItem("paymentMethod"));
    const isSubscription = useRef(!!localStorage.getItem("subscription"));
    const subscriptionInfo = useRef(JSON.parse(localStorage.getItem('subscriptionInfo')));
    const paymentInfo = useRef(JSON.parse(localStorage.getItem('paymentInfo')));
    const [isLoading, setIsLoading] = useState(false);

    const onNavigate = async () => {
        isUae && localStorage.removeItem('isUae');
        setIsLoading(true);
        await paymentCheck(plateNumber.current, parkingUid.current).then((resp) => {
            if (isSubscription.current) {
                navigate(`/parking/${parkingUid.current}/info/subscription/payment`, {
                    state: {
                       ...subscriptionInfo.current
                    },
                });
            } else {
                if (resp?.status === 200 && resp?.data?.result !== 1) {
                    navigate(`/parking/${parkingUid.current}/info/payment`, {
                        state: {
                           ...paymentInfo.current
                        },
                    });
                } else if (window.location.href.includes('t.parqour.com') || window.location.href.includes('q.parqour.com')) {
                    navigate(
                        `/${parkingUid.current}`
                    );
                } else {
                    navigate(
                        `${isUae ? '/uae' : ''}/parking/${localStorage.getItem(
                            "parkingUid"
                        )}/${paymentMethod.current}`
                    );
                }
            }
        }).finally(() => setIsLoading(false));

    }

    return (
        <FormContainer withApps={true}>
            <div className="flex flex-col items-center">
                <img src={Error} alt="Error" className="mb-6"/>
                <p className="mb-9 text-2xl text-center text-surface font-medium">{t('something_went_wrong')}</p>
                {isLoading ? <Loader/> :
                    <Button text={t('try_again')} styles="bg-[#F2F4F7] hover:bg-disabled" textStyles="text-link"
                            onClick={onNavigate}/>}
            </div>
        </FormContainer>
    );
};

export default PaymentErrorForm;
